<template>
  <BlockContainer>
    <div
      class="p-10 md:p-20 flex flex-grow flex-col items-center justify-center"
    >
      <div class="flex flex-wrap justify-center">
        <span
          v-for="{ heading } in data"
          :key="heading"
          v-text="heading.toUpperCase()"
          @click="activeJewellery = heading"
          class="cursor-pointer text-lg mx-4 whitespace-no-wrap mt-2"
          :class="{ underline: activeJewellery === heading }"
        />
      </div>
      <div v-for="jewellery in data" :key="jewellery.heading">
        <div v-if="activeJewellery === jewellery.heading" class="max-w-4xl">
          <CoreRichText
            class="mt-10 md:mt-20 text-center h3-heading h6-caption img-size flex flex-col items-center"
            :document="jewellery.body"
          />
        </div>
      </div>
    </div>
  </BlockContainer>
</template>

<script>
import { getJewellery } from "@io";
import { BlockContainer } from "@c/Block";

export default {
  name: "Jewellery",

  components: {
    BlockContainer
  },

  data: () => ({
    data: null,
    activeJewellery: ""
  }),

  async created() {
    const response = await getJewellery();
    this.data = response.ordering.map(diamond => diamond.fields);
    this.activeJewellery = this.data[0].heading;
  }
};
</script>

<style scoped>
.img-size ::v-deep picture {
  margin-top: 4rem;
  margin-bottom: 4rem;
  width: 50%;
}

.img-size ::v-deep img {
  max-width: 25rem;
  display: inline-block;
}

.h3-heading ::v-deep h3 {
  font-size: 1.25rem;
  font-weight: bold;
}

.h6-caption ::v-deep h6 {
  font-size: 0.8rem;
}
</style>
